
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      users_dateils: [],

      VendorCatogries: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchVendorCatogries: null,
      VendorCatogriesSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addVendorCatogries() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addVendorCatogries",
        inputs: [
          {
            "model": "name",
            "type": "text",
            "label": "name"
          },
          {
            "model": "image",
            "type": "text",
            "label": "image"
          },
          {
            model: "users_dateils",
            type: "select",
            options: (() => {
              return this.users_dateils.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.users_dateils",
          },


        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("vendor-catogries", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editVendorCatogries(app) {
      var data = {
        title: "popups.vendor_catogries",
        inputs: [
          {
            "model": "name",
            "type": "text",
            "label": "name",
            "value": app.name
          },
          {
            "model": "image",
            "type": "text",
            "label": "image",
            "value": app.image
          },

        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("vendor-catogries", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateVendorCatogries(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            "model": "name",
            "type": "text",
            "label": "name"
          },
          {
            "model": "image",
            "type": "text",
            "label": "image"
          },
          {
            model: "users_dateils",
            type: "select",
            options: (() => {
              return this.users_dateils.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.users_dateils",
          },


        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("vendor-catogries", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.VendorCatogriesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("vendor-catogries/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.VendorCatogries = res.data;
          console.log("######## files", this.VendorCatogries);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.VendorCatogriesSearchMode = false;
      this.get(this.page);
    },

    getVendorCatogries() {
      this.http.get("vendor-catogries").then((res) => {
        this.VendorCatogries = res.data;
      });
    },
    deleteVendorCatogries(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("vendor-catogries", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("vendor-catogries/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.VendorCatogries = res.data;
          res.data.forEach((item) => {
            this.users_dateils.push(item.users_dateils)
          })


        });
    },
  },
  created() {
    this.get(1);
    this.getVendorCatogries();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.vendor_catogries.text')" />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input @keyup="search()" type="text" class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
              style="background-color: #2a3042 !important" />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button class="btn-close text-light" v-if="VendorCatogriesSearchMode" @click="cancelappsearchMode()"></button>
        <button type="button" @click="addVendorCatogries()" class="btn btn-light float-end mb-4">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope='col'>{{ $t('vendor_catogries.id') }}
          </th>
          <th scope='col'>{{ $t('vendor_catogries.name') }}
          </th>
          <th scope='col'>{{ $t('vendor_catogries.image') }}
          </th>
          <th scope='col'>{{ $t('vendor_catogries.created') }}
          </th>
          <th scope='col'>{{ $t('vendor_catogries.updated') }}
          </th>
          <th scope='col'>{{ $t('vendor_catogries.operations') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app) in VendorCatogries" :key="app" class="text-center">
          <td>{{ app?.name }}</td>
          <td>{{ app?.image }}</td>
          <td>{{ app?.created?.split('T')[0] }}</td>
          <td>{{ app?.updated?.split('T')[0] }}</td>

          <td class="d-flex">
            <a class="btn btn-primary mx-1" @click="editVendorCatogries(app)" href="javascript: void(0);" role="button">{{
              $t("popups.edit") }}</a>
            <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteVendorCatogries(app)">{{
              $t("popups.delete") }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




